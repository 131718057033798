<template>
  <div class="common_sub_container">
    <h2 class="sections_to_hide">CARRIER INVOICE RECONCILIATION</h2>
    <section class="sections_to_hide">
      <div class="form_section1">
        <table class="scoped_cell_width_adj">
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Invoice Upload
                  <span></span>
                </label>
                <flat-pickr
                  class="date_for_pos_fixed"
                  v-model="daterange"
                  :config="configDate"
                  placeholder="Date"
                  name="birthdate"
                  @on-close="dateChanged">
                  </flat-pickr>
              </div>
            </td>
          <td>
            <div class="label_and_element_wrapper">
              <label>
                Carrier
                <span></span>
              </label>
                  <select v-model="post_data.delivery_provider" @change="fetchReport">
                    <option value="all">All</option>
                    <option
                      v-for="(carrier,i) in carriers"
                      :key="'carriers'+i"
                      :value="carrier.id"
                    >{{carrier.name}}</option>
                  </select>
              </div>
            </td>
            <td>
              <div v-if="post_data.booking_type != 'encode'">
                <div class="label_and_element_wrapper">
                  <label>
                    Booking Ref
                    <span></span>
                  </label>
                  <input type="text" v-model="post_data.booking_number" @change="fetchReport">
                </div>
              </div>
            </td>
            <td>
              <div v-if="post_data.booking_type != 'encode'">
                <div class="label_and_element_wrapper">
                  <label>
                    Show Data
                    <span></span>
                  </label>
                  <select v-model="post_data.show_data"  @change="fetchReport">
                    <option value="">All</option>
                    <option value="reconciled">Reconciled</option>
                    <option value="unreconciled">Unreconciled</option>
                  </select>
                </div>
              </div>
            </td>
            <!-- <td>
              <div v-if="post_data.booking_type != 'encode'">
                <div class="label_and_element_wrapper">
                  <label>
                    Service Name
                    <span></span>
                  </label>
                  
                  <select v-model="post_data.delivery_service">
                    <option value="all">All</option>
                    <option
                      v-for="(service,i) in services"
                      :key="'services'+i"
                      :value="service.id"
                    >{{service.name}}</option>
                  </select>
                </div>
              </div>
            </td> -->

            <!-- <td>
              <div class="label_and_element_wrapper">
                <label>
                  Group By
                  <span></span>
                </label>
                <select v-model="post_data.groupby" @change="resetData">
                  <option value="goods">Type of Goods</option> 
                  <option value="pickup_name">Sender Name</option>
                  <option value="pickup_postal_code">Pickup Postcode</option>
                  <option value="delivery_postal_code">Delivery Postcode</option>
                </select>
              </div>
            </td> -->
                 <!-- <td>
              <div class="label_and_element_wrapper">
                <label>
                  Type of Error
                  <span></span>
                </label>
                <select v-model="post_data.type_of_error" @change="resetData">
                  <option value="all">All</option>
                  <option value="1">OK</option>
                  <option value="0">Error</option>
                  
                </select>
              </div>
            </td> -->
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>
              <a class="link_bt bt_save" @click="fetchReport">Search</a>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
    </section>
    <section v-if="show_data" class="reports_to_print">
      <div class="despatch_summary_wrapper" v-if="Object.keys(reports).length > 0">
        <header>
          <h3>Carrier Invoice Reconciliation Monthly Summary</h3>
          <div class="right_part">
            <a title="Download" ref="download" @click="downloadReport">
              <i class="material-icons">arrow_drop_down_circle</i>
            </a>
            <a title="Print" @click="printRecord">
              <i class="material-icons">print</i>
            </a>
          </div>
        </header>
        <div class="despatch_summary_table_container">
          <table>
            <thead>
              <tr>
                <th>Booking Date</th>
                <th>File Upload Date</th>
                <th>Booking Ref</th>
                <th>Invoice Value</th>
                <th>Matching Booking Ref</th>
                <th>Booking Value</th>
                <th>Recon Status</th>
                <th>Courier Name</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="report in reports">
                <td>
                  <template v-if="report.request_date">
                    {{ report.request_date | formatDate }}
                  </template>
                  <template v-else>
                    No invoice uploaded
                  </template>
                </td>
                <td>
                  <template v-if="report.invoice_date && report.id">
                    {{report.invoice_date | formatDate}}
                  </template>
                  <template v-else-if="report.invoice_date">
                    {{report.invoice_date | formatDate}}
                  </template>
                  <template v-else>
                    No invoice uploaded
                  </template>
                </td>
                <td>
									{{ report.airway_bill }}
                </td>
                <td>{{report.invoice_date ? report.invoice_upload.invoice_value : 'No invoice uploaded'}}</td>
                <td>
                  <template v-if="report.invoice_date">
                    {{report.invoice_date ? 'Y' : 'N'}}
                  </template>
                  <template v-else>
                    No invoice uploaded
                  </template>
                </td>
                <td>{{report.invoice_upload ? report.invoice_upload.booking_value : 'No invoice uploaded'}}</td>
                <td>
                  <template v-if="report.invoice_date">
                    <template v-if="report.invoice_upload.booking_value == report.invoice_upload.invoice_value">
                      Amounts are Equal
                    </template>  
                    <template v-else-if="report.invoice_upload.invoice_value > report.invoice_upload.booking_value">
                      Inv Amt > Booking Amt
                    </template>  
                    <template v-else-if="report.invoice_upload.invoice_value < report.invoice_upload.booking_value">
                      Inv Amt &lt; Booking Amt
                    </template>  
                  </template>  
                  <template v-else>
                    No invoice uploaded
                  </template>
                </td>
                <td>
                  <template v-if="report.invoice_upload">
                    {{report.invoice_upload.provider ? report.invoice_upload.provider.name : ''}}
                  </template>
                  <template v-else>
                    No invoice uploaded
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <h4 v-if="Object.keys(reports).length == 0">No data found</h4>
    </section>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  name: "ReportsDelivery",
  components: {
    flatPickr
  },
  data() {
    return {
      daterange: [
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD")
      ],
      date: null,
      enableTime: true,
      carriers: [],
      services: [],
      reports: {},
      post_data: {
        from: moment(new Date()).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
        delivery_provider: "all",
        delivery_service: "all",
        show_data: "",
        groupby: "goods",
        type_of_error:"all",
        booking_number:'',
      },
      api_url: "",
      configDate: {
        mode: "range",
        format: "d-m-Y",
          altFormat: "d-m-Y",
          altInput: true
      },
       save_data:
      {
       
        actual_courier_amount:"",
        is_actual_courier_amount:1,
        courier_amount_comment:"",

      },
      show_data: false,
    };
  },
  filters: {
    formatDate(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('DD/MM/YYYY')
    }
  },
  created() {
    this.api_url = process.env.VUE_APP_API_URL;
    this.$emit("breadCrumbData", ["Invoice Check", "Carrier Invoice Reconciliation"]);
    this.fetchCarrier();
  },
  methods: {
    dateChanged(daterange) {
      this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
      this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
    },
    
   fetchCarrier() {
      this.axios
        .get("/api/provider")
        .then(response => {
          this.carriers = response.data.providers;
        })
        .catch(error => {
          this.toast.error();
        });
    },
     fetchService() {
      if(this.post_data.delivery_provider){
      this.axios.get("/api/provider/carrier/"+this.post_data.delivery_provider)
        .then(response => {
          this.services = response.data.provider.servicenames;

           if(this.services.length > 0){
              this.post_data.delivery_service = this.services[0].id;
            }
          })
       
        .catch(error => {
          console.log(error);
        });
      }
    },
    resetData() {
      this.show_data = false;
      this.reports = {};
    },
    fetchReport() {
      console.log(JSON.stringify(this.post_data))
      this.axios
        .post("/api/reports/getcarrierinvoicereport", this.post_data)
        .then(response => {
          this.show_data = true;
          this.reports = response.data.data;
        })
        .catch(error => {
          console.log(e);
        });
    },
SaveCourierRate(value){
  
  this.save_data.booking_id=value.id;
  this.save_data.actual_courier_amount=value.actual_courier_amount;
  this.save_data.is_actual_courier_amount=value.is_actual_courier_amount;
  this.save_data.courier_amount_comment=value.courier_amount_comment;
  
   if(!this.save_data.courier_amount_comment)
   this.save_data.courier_amount_comment= "";

    if(this.save_data.is_actual_courier_amount == 0 && this.save_data.courier_amount_comment =="" )
    {
      
     
     this.toast.error('Please Enter Comment');

  }
  else
   {
      this.axios.post("/api/reports/getcarrierinvoicereport/savedata/" +this.save_data.booking_id,this.save_data)
        .then(response => {
          this.toast.success(response.data.msg);
          this.$emit('redirect');
        })
     
        .catch(error =>{
          console.log(error);
        }); }
    },

    downloadReport() {
      let method = "POST";
      let input;
      let form = document.createElement("form");
      form.setAttribute("method", method);
      form.setAttribute(
        "action",
        this.api_url +
          "/api/reports/getcarrierinvoicereport/download?token=" +
          localStorage.token
      );
      Object.keys(this.post_data).forEach(item => {
        input = document.createElement("input");
        input.type = "text";
        input.name = item;
        input.value = this.post_data[item];
        form.appendChild(input);
      });
      this.$refs.download.appendChild(form);
      form.submit();
      this.$refs.download.removeChild(form);
    },
    printRecord() {
      window.print();
    }
  }
};
</script>
<style>
.flatpickr-calendar.lol {
  border: 5px solid red;
}
</style>
<style scoped  >
.scoped_cell_width_adj td {
  width: 16.6%;
}
.link_bt {
  margin-left: 10px;
}
.despatch_summary_wrapper header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}
.despatch_summary_wrapper header h3 {
  font-size: 20px;
  font-weight: 600;
}
.despatch_summary_wrapper header .right_part {
  width: auto;
  height: 20px;
}
.despatch_summary_wrapper header .right_part a {
  display: inline-block;
  margin-right: 20px;
}
.despatch_summary_wrapper header .right_part a i {
  font-size: 32px;
}
.despatch_summary_wrapper {
  padding: 20px;
  background: #efefef;
}
.despatch_summary_table_container {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
  min-width: 800px;
  overflow-x: auto;
}
.despatch_summary_table_container table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
}
.despatch_summary_table_container table td {
  padding: 10px;
  border: 1px solid #ddd;
}
</style>
<style scoped>
@media print {
  .sections_to_hide {
    display: none;
  }
  .reports_to_print {
    display: block;
  }
  .despatch_summary_wrapper {
    padding: 0;
    background: none;
  }
  .despatch_summary_wrapper header .right_part {
    display: none;
  }
  .despatch_summary_table_container {
    padding: 0;
    border: 1px solid #999;
  }
  .despatch_summary_table_container h2 {
    font-size: 16px;
  }
  .despatch_summary_table_container table td {
    border-color: #999;
    font-size: 10px;
  }
  @page {
    size: landscape;
  }
}
</style>